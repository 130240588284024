<template>
    <b-overlay :show="is_busy" rounded="sm">
        <b-container fluid>
            <b-row class="mb-2">
                <b-col md="4">
                    <h2><strong>List of Classes</strong></h2>
                </b-col>
            </b-row>

            <div class="card">
                <div class="card-body">
                    <div class="row my-1">
                        <div class="col-md-6 col-12">
                            <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                            class="my-1"
                            ></b-form-input>
                        </div>

                        <div class="col-md-6 col-12 mt-1">
                            <b-button variant="outline-primary" size="sm" @click="newModal" class="ml-1">
                                <i class="fa fa-plus"></i>  Add Class
                            </b-button>
                        
                            <b-button variant="outline-danger" size="sm" class="ml-1" v-if="action.selected.length" @click="onDeleteAll"><i class="fa fa-trash"></i> Delete Selected</b-button>

                            <b-button disabled size="sm" variant="outline-danger" v-else class="ml-1"> <i class="fa fa-trash"></i> Delete Selected</b-button>
                       
                            <b-button variant="outline-secondary" size="sm" @click="promote" class="ml-1">
                                Promote Students
                            </b-button>                      
                        </div>
                    </div>
                 
                    <b-table
                        responsive
                        :items="items"
                        :fields="fields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :bordered="bordered" 
                        :hover="hover"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        show-empty
                        id="my-table"
                        small
                        @filtered="onFiltered"
                    >   
                        <template #head(index)="">
                            <input type="checkbox" v-model="selectAll">
                        </template>

                        <template #cell(actions)="room">
                            <b-dropdown id="dropdown-right" text="Action" variant="info" size="sm">
                                <b-dropdown-item href="javascript:void(0)" @click="room.toggleDetails">View Class</b-dropdown-item>
                                <!-- <b-dropdown-item href="javascript:void(0)" @click="allResults(room.item.id)">View Result</b-dropdown-item> -->
                                <b-dropdown-item href="javascript:void(0)" @click="editModal(room.item)">Edit Class</b-dropdown-item>
                                <b-dropdown-item href="javascript:void(0)" @click="onDeleteAll(room.item.id)">Delete Class</b-dropdown-item>
                          </b-dropdown>
                        </template>

                        <template #cell(index)="data">
                            <!--{{ data.index + 1 }}-->
                            <input type="checkbox" v-model="action.selected" :value="data.item.id" number>
                        </template>

                        <template #cell(promotion)="room">
                            <span v-if="room.item.promotion=='No Class Assigned'" class="text-danger">
                                    <small><i>{{ room.item.promotion }}</i></small>
                                </span>
                                <span v-else>
                                    {{ room.item.promotion }}
                            </span>
                        </template>

                        <template #row-details="row">
                            <div class="cont border">
                                <b-card no-body>
                                      
                                    <div class="row mb-1">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6">
                                            <b-button variant="outline-primary" size="sm" @click="row.toggleDetails" class="float-right m-1">
                                                Hide Details
                                            </b-button>
                                        </div>
                                    </div>

                                    <b-tabs pills card>
                                        <b-tab title="Learners" active>
                                            <b-card-text>
                                                <div class="table-responsive p-0" v-if="row.item.all_students.length > 0">
                                                    <table class="table table-hover table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Fullname</th>
                                                                <th>Reg Number</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="student in row.item.all_students" :key="student.id">
                                                                <td>{{ student.student.lastname }} {{ student.student.firstname }} {{ student.student.middlename }}</td>
                                                                <td>{{ student.student.roll }}</td>
                                                                <td>
                                                                    <b-dropdown id="dropdown-right" text="Action" variant="info" size="sm">
                                                                        <b-dropdown-item href="javascript:void(0)" @click="viewProfile(student.student)">View Profile</b-dropdown-item>

                                                                        <b-dropdown-item href="javascript:void(0)" @click="viewResult(student.student)">View Result</b-dropdown-item>

                                                                    </b-dropdown>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div v-else  class="alert alert-info text-center p-2">
                                                    <h3><strong>No student assigned to this class.</strong></h3>
                                                </div>
                                            </b-card-text>
                                        </b-tab>
                                        <b-tab title="Subjects">
                                            <b-card-text>
                                                <div class="row mb-1">
                                                    <div class="col-md-6">
                                                        <h6>{{ row.item.slug }} Subjects</h6>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <b-button variant="outline-info" size="sm" @click="addSubject(row.item.id)" class="float-right ml-1">
                                                            <i class="fa fa-plus"></i>  Add Subjects
                                                        </b-button>

                                                        <b-button variant="outline-danger" size="sm" @click="removeSubject(row.item.id)" class="float-right ml-1">
                                                            <i class="fa fa-plus"></i>  Remove Subjects
                                                        </b-button>    
                                                    </div>
                                                </div>
                                                <div class="table-responsive p-0" v-if="row.item.all_subjects.length > 0">
                                                    <table class="table table-hover table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Name</th>
                                                                <th>Subject Teacher</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="subject in row.item.all_subjects" :key="subject.id">
                                                                <td><input type="checkbox" v-model="action.subject" :value="subject.id" number></td>
                                                                <td>{{ subject.subject }}</td>
                                                                <td> {{ subject.teacher }}</td>
                                                                <td>
                                                                    <b-dropdown id="dropdown-right" text="Action" variant="info" size="sm">
                                                                        <b-dropdown-item href="javascript:void(0)" @click="viewSubjectResult(subject)">View Result</b-dropdown-item>
                                                                        <b-dropdown-item href="javascript:void(0)" @click="addSubjectTeacher(subject.id)">Assign Teacher</b-dropdown-item>
                                                                    </b-dropdown>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                
                                                <div v-else  class="alert alert-info text-center p-2">
                                                    <h3><strong>No subject assigned to this class.</strong></h3>
                                                </div>
                                            </b-card-text>
                                        </b-tab>
                                    </b-tabs>
                                </b-card>
                            </div>
                        </template>
                    </b-table>
                </div>

                <div class="card-footer">
                    <b-row class="mb-2">
                        <b-col md="4">
                            <b-form-group
                                label="Showing"
                                label-for="per-page-select"
                                label-cols-md="4"
                                label-align-sm="right"
                                label-size="sm"
                                class="mb-0"
                            >
                                <b-form-select
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                                class="def"
                                ></b-form-select>
                                 of {{ items.length }} Items
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-pagination
                                aria-controls="my-table"
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                            ></b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <b-modal ref="addNew" :title="title" hide-footer>
                <form @submit.prevent="editmode ? updateUser() : createUser()">
                    <span v-if="editmode==false">
                        <b-form-group label="Category:" label-for="category">
                            <select v-model="form.name" class="form-control" required>
                                <option value=null> -- Select Category-- </option>
                                <option v-for="option in zall_classes" :key="option.id" v-bind:value="option.id">
                                    {{ option.title }}
                                </option>
                            </select>
                        </b-form-group>
                    </span>
                    <span v-else>
                        <div class="form-group">
                            <label>Title</label>
                            <input v-model="form.slug" type="text" name="slug" class="form-control" :class="{ 'is-invalid': form.errors.has('slug') }">
                        </div>

                        <div class="form-group">
                            <label>Acronym</label>
                            <input v-model="form.name" type="text" name="name" class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                        </div>
                    </span>
                    <div class="form-group">
                        <label>Form</label>
                        <input v-model="form.form" type="text" name="form" class="form-control" :class="{ 'is-invalid': form.errors.has('form') }">
                    </div>
                    
                    <b-form-group label="Select Form Teacher" label-for="category">
                        <select v-model="form.form_teacher" class="form-control">
                            <option value=null> -- Form Teacher-- </option>
                            <option v-for="option in staff" :key="option.id" v-bind:value="option.id">
                                {{ option.form }}
                            </option>
                        </select>
                    </b-form-group>
                    <!-- <v-select label="form" :options="staff" @input="setSelected" ></v-select> -->
                 
                    <b-form-group label="Promotion Class" label-for="PromotionClass">
                        <select v-model="form.promotion_class" class="form-control">
                            <option value=null> -- Promotion Class-- </option>
                            <option v-for="option in all_classes" :key="option.id" v-bind:value="option.id">
                                {{ option.title }}
                            </option>
                        </select>
                    </b-form-group>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                </form>
            </b-modal>

            <b-modal ref="promote" :title="title" hide-footer>
                <div>
                    <h4>Note:</h4>
                    <ul>
                        <li>Note that qualified learners in the old class will be promoted to the new class </li>
                        <li>Also ensure that all the classes have been assigned a promotion class.</li>
                    </ul>

                    <div class="form-group">
                        <label>Select Next Session</label>
                        <v-select label="name" :options="sessions" @input="setSession" ></v-select>
                    </div>

                    <table class="table table-hover table-striped text-center">
                        <thead>
                            <tr>
                                <th>Old Class</th>
                                <th>New Class</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="room in all_classes" :key="room.id">
                                <td>{{ room.title }}</td>
                                <td>
                                <span v-if="room.promotion=='No Class Assigned'" class="text-danger">
                                    <small><i>{{ room.promotion }}</i></small>
                                </span>
                                <span v-else>
                                    {{ room.promotion }}
                                </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-success" @click="promoteAll">Promote</button>
                </div>
            </b-modal>

            <b-modal ref="allSubject" size="lg" :title="title" hide-footer>
                <div class="row">
                    <div class="col-md-3" v-for="subject in allsubjects" :key="subject.id">
                        <div class="m-1">
                            <input type="checkbox" v-model="getsubject.selected" :value="subject.id" number> {{ subject.name }}
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <b-button variant="success" size="lg" v-if="getsubject.selected.length" class="btn-block" @click="onAddAll">Update Subject</b-button>
                </div>
            </b-modal>

            <b-modal ref="allResult" :title="title" hide-footer>
                <b-form @submit.prevent="getResult()">
                    <b-form-group class="">
                        <label>Select Session</label>
                        <select v-model="allres.session" class="form-control" required>
                            <option value=null> -- Select Session-- </option>
                            <option v-for="option in sessions" :key="option.id" v-bind:value="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </b-form-group>
                    
                    <b-form-group class="">
                        <label>Select Term</label>
                        <b-form-select v-model="allres.term" :options="terms"></b-form-select>
                    </b-form-group>
                    
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success btn-lg btn-block">Submit</button>
                    </div>
                </b-form>
            </b-modal>

            <b-modal ref="subjectTeacher" :title="title" hide-footer>
                <form @submit.prevent="updateSubjectTeacher()">
                    <div class="modal-body">
                        <b-form-group label="Assign Teacher" label-for="category">
                            <select v-model="subjectteacher.staff" class="form-control">
                                <option value=null> -- Select Teacher-- </option>
                                <option v-for="option in staff" :key="option.id" v-bind:value="option.id">
                                    {{ option.form }}
                                </option>
                            </select>
                        </b-form-group>
                    </div>
                    <div class="modal-footer">
                        <b-button type="submit" variant="primary">Assign</b-button>
                    </div>
                </form>
            </b-modal>

            <b-modal ref="viewSubjectResult" :title="title" hide-footer>
                <b-form @submit.prevent="updateSubject()">
                    <b-form-group class="">
                        <label>Select Session</label>
                        <select v-model="formSubject.session" class="form-control" required>
                            <option value=null> -- Select Session-- </option>
                            <option v-for="option in sessions" :key="option.id" v-bind:value="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </b-form-group>
                    
                    <b-form-group class="">
                        <label>Select Term</label>
                        <b-form-select v-model="formSubject.term" :options="termss"></b-form-select>
                    </b-form-group>
                 
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success btn-lg btn-block">Submit</button>
                    </div>
                </b-form>
            </b-modal>

            <b-modal ref="viewResult" :title="title" hide-footer>
                <b-form @submit.prevent="updateUserResult()">
                    <div class="modal-body">

                        <b-form-group class="">
                            <label>Select Session</label>
                            <select v-model="formResult.session" class="form-control" required>
                                <option value=null> -- Select Session-- </option>
                                <option v-for="option in sessions" :key="option.id" v-bind:value="option.id">
                                    {{ option.name }}
                                </option>
                            </select>
                        </b-form-group>
                        
                        <b-form-group class="">
                            <label>Select Term</label>
                            <b-form-select v-model="formResult.term" :options="terms"></b-form-select>
                        </b-form-group>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-success btn-lg btn-block">Submit</button>
                    </div>
                </b-form>
            </b-modal>
      
        </b-container>
    </b-overlay>
</template>

<script>
    import axios from 'axios';
    import { Form } from 'vform';
    
    export default {
        data() {
            return {
                is_busy: false,
                editMode: false,
                title: '',
                items: [],
                fields: [
                    { key: 'index', label: '' },
                    { key: 'slug', label: 'Name'},
                    { key: 'title', label: 'Title'},
                    { key: 'teacher', label: 'Form Teacher'},
                    { key: 'subject', label: 'Subject'},
                    { key: 'number_of_student', label: 'Student' },
                    { key: 'promotion', label: 'Promotion Class' },
                    { key: 'actions', label: 'Actions' }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 15,
                bordered: true,
                hover: true,
                fixed: true,
                pageOptions: [5, 10, 15, 25, 50, 100, { value: 100, text: "All" }],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: ''
                },
                sessions : [],
                terms: [{ text: 'Select Term', value: null }, { text: 'First Term', value: 1 }, { text: 'Second Term', value: 2 }, { text: 'Third Term', value: 3 }, { text: 'Annual', value: 4 }],
                termss: [{ text: 'Select Term', value: null }, { text: 'First Term', value: 1 }, { text: 'Second Term', value: 2 }, { text: 'Third Term', value: 3 }],
                is_busy: false,
                editmode: false,
                zall_classes : [],
                all_classes : [],
                staff : [],
                action: {
                    selected: [],
                    subject: [],
                    student_selected: [],
                    id: '',
                },

                formSession: {
                    session_id: '',
                },
    
                form: new Form({
                    id : '',
                    form : '',
                    name: null,
                    slug: '',
                    capacity: '',
                    form_teacher: '',
                    promotion_class: '',
                }),

                allres: {
                    id: '',
                    session: null,
                    term: null,
                },
                allsubjects: [],
                getsubject: {
                    selected: [],
                    id: '',
                },

                subjectteacher: {
                    staff: '',
                    id: '',
                },

                formSubject: new Form({
                    class_id: '',
                    subject_id: '',
                    session: null,
                    term: null,
                }),
                formResult: new Form({
                    student: '',
                    session: null,
                    term: null,
                    name: ''
                }),
            }
        },

        created() {
            this.roleHelper(['1', '5']);
            this.load();
        },

        methods: {
            setSession(value) {
                this.formSession.session_id = value.id;
            },

            setClass(value) {
                this.form.promotion_class = value.id;
            },

            setSelected(value) {
                this.form.form_teacher = value.id;
            },

            load() {
                if(this.is_busy) return;
                this.is_busy = true;
                axios.get('/classes')
                .then(res => {
                    this.items = res.data.data.all_classes;
                    this.totalRows = this.items.length
                    this.zall_classes = res.data.data.zall_classes;
                    this.sessions = res.data.data.sessions;
                    this.staff = res.data.data.staff;
                    this.all_classes = res.data.data.all_classes;
                    this.allsubjects = res.data.data.allsubjects;
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.is_busy = false;
                });
            },

            newModal(){
                this.editmode = false;
                this.form.reset();
                this.title = 'Create Class';
                this.$refs['addNew'].show()
                // $('#addNew').modal('show');
            },

            promote(){
                this.title = 'Promote Students';
                this.$refs['promote'].show()
            },

            promoteAll(){
                this.$refs['promote'].hide()
                // $('#promote').modal('hide');
                console.log(this.formSession)
                axios.get('/promote', { params: this.formSession})
                .then(()=>{  
                    Swal.fire(
                        "Created!",
                        'Qualified learners have been promoted',
                        "success"
                    );
                    this.load();
                })
                .catch(()=>{
                    Toast.fire({
                    icon: 'error',
                    title: 'Some error occured! Please try again'
                    });
                });

                this.load();
            },

            createUser(){
                this.$refs['addNew'].hide()
                this.form.post('/classes')
                .then((response)=>{ 
                    Swal.fire(
                        "Created!",
                        response.data.message,
                        "success"
                    );
                    this.load();
                })
                .catch((err)=>{
                    Swal.fire(
                        "Error!",
                        err.response.data.data.error,
                        "error"
                    );
                });
                this.load();
            },

            editModal(room){
                this.editmode = true;
                this.form.reset();
                this.title = 'Edit Class';
                this.form.fill(room);
                this.$refs['addNew'].show()
            },

            updateUser(){
                this.$refs['addNew'].hide()
                this.form.put('/classes/'+this.form.id)
                .then((response)=>{ 
                    Swal.fire(
                        "Created!",
                        response.data.message,
                        "success"
                    );
                    this.load();
                })
                .catch((err)=>{
                    Swal.fire(
                        "Error!",
                        err.response.data.data.error,
                        "error"
                    );
                });
                this.load();
            },

            onDeleteAll(id) {
                if(id){
                    this.action.selected.push(id);
                }
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!, Deleting these classes will wipe out all related data in that class",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!"
                })
                .then(result => {
                    if (result.value) {
                    axios.get('/classes/delete', { params: this.action})
                    .then(() => {
                        Swal.fire(
                            "Deleted!",
                            "Class(es) deleted.",
                            "success"
                        );
                        this.load();
                    })

                    .catch(() => {
                        Swal.fire(
                            "Failed!",
                            "Ops, Something went wrong, try again.",
                            "warning"
                        );
                    });
                    }
                });
                this.load();
            },

            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },

            allResults(id) {
                this.allres.id = id;
                this.title = 'View Class Result';
                this.$refs['allResult'].show();
            },

            getResult(){
                if(!this.allres.session || !this.allres.term){
                    Swal.fire(
                        "Failed!",
                        "Please select all the requirements.",
                        "warning"
                    );
                }
                else
                {
                    this.$refs['allResult'].hide();
                    this.$router.push({path: '/result/viewall/'+ this.allres.id + '/' + this.allres.session + '/' + this.allres.term });
                } 
            },

            addSubject(id){
                this.getsubject.id = id;
                this.title = 'Assign Subjects to Class';
                this.$refs['allSubject'].show();
            },

            removeSubject(id) {
                this.action.id = id;
                Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!, Deleting these subjects will wipe out all related data in that class",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, remove it!"
                })
                .then(result => {
                    if (result.value) {
                    axios.get('/classes/subject/delete', { params: this.action})
                    .then(() => {
                        Swal.fire(
                            "Deleted!",
                            "Subject(s) deleted.",
                            "success"
                        );
                        this.load();
                    })

                    .catch(() => {
                        Swal.fire(
                            "Failed!",
                            "Ops, Something went wrong, try again.",
                            "warning"
                        );
                    });
                    }
                });
                this.load();
            },

            onAddAll() {
                this.$refs['allSubject'].hide();
                if(this.is_busy) return;
                this.is_busy = true;

                axios.get('/classes/subject/add', { params: this.getsubject})
                .then(() => {
                    Swal.fire(
                        "Success!",
                        "Subject(s) Added.",
                        "success"
                    );
                    this.load();
                })
                .catch(() => {
                    Swal.fire(
                        "Failed!",
                        "Ops, Something went wrong, try again.",
                        "warning"
                    );
                })
                .finally(() => {
                    this.is_busy = false;
                    this.load();
                });
            },

            addSubjectTeacher(id){
                this.title = 'Update Subject Teacher';
                this.subjectteacher.id = id;
                this.subjectteacher.staff_id = '';
                this.$refs['subjectTeacher'].show();
            },

            updateSubjectTeacher() {
                this.$refs['subjectTeacher'].hide();

                if(this.is_busy) return;
                this.is_busy = true;

                axios.get('/classes/subjectteacher',{ params: this.subjectteacher})
                .then(() => {
                    Swal.fire(
                        "Success!",
                        "Subject Teacher Assigned.",
                        "success"
                    );
                    this.load();
                })
                .catch(() => {
                    Swal.fire(
                        "Failed!",
                        "Ops, Something went wrong, try again.",
                        "warning"
                    );
                })
                .finally(() => {
                    this.is_busy = false; 
                    this.load();
                });
            },

            viewSubjectResult(subject) {
                this.title = subject.subject + ' Resultsheet';
                this.formSubject.class_id = subject.classes_id;
                this.formSubject.subject_id = subject.subject_id;
                this.$refs['viewSubjectResult'].show();
            },

            updateSubject(){
                this.$refs['viewSubjectResult'].hide();
               
                axios.post('/result', this.formSubject)
                .then(()=>{
                    this.$router.push({path: '/result/add/'+ this.formSubject.class_id + '/' + this.formSubject.session + '/' + this.formSubject.subject_id + '/' + this.formSubject.term });
                }) 
                .catch();
            },

            viewProfile(student) {
                this.$router.push({path: '/administration/student/'+ student.id });
            },

            viewResult(student) {
                this.formResult.student = student.id;
                this.formResult.name = student.lastname + ' ' + student.firstname + ' ' + student.middlename;
                this.title =this.formResult.name;
                this.$refs['viewResult'].show();
            },

            updateUserResult(){
                
                if(!this.formResult.session || !this.formResult.term){
                    Swal.fire(
                        "Failed!",
                        "Please select all the requirements.",
                        "warning"
                    );
                }
                else
                {
                    this.$refs['viewResult'].hide();
                    if(this.formResult.term==4) {
                        this.$router.push({path: '/result/view/annual/'+ this.formResult.student + '/' + this.formResult.session + '/' + this.formResult.term });
                    }
                    else {
                        this.$router.push({path: '/result/view/'+ this.formResult.student + '/' + this.formResult.session + '/' + this.formResult.term });
                    }
                    
                } 
            },
        },

        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                .filter(f => f.sortable)
                .map(f => {
                return { text: f.label, value: f.key }
                })
            },

            selectAll: {
                get: function () {
                    return this.items ? this.action.selected.length == this.items.length : false;
                },

                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.items.forEach(function (room) {
                            selected.push(room.id);
                        });
                    }

                    this.action.selected = selected;
                }
            },

            selectAllSubject: {
                get: function () {
                    return this.items ? this.action.subject.length == this.items.length : false;
                },

                set: function (value) {
                    var selected = [];

                    if (value) {
                        this.items.forEach(function (room) {
                            selected.push(room.id);
                        });
                    }

                    this.action.selected = selected;
                }
            }
        },
    }
</script>
<style>
    .cont {
        background: #F3F2F7;
    }

    .def {
        width: 70px;
    }
</style>